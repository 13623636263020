import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export abstract class SyncService extends ResourceService<any> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'sync';
    }

    getCompareOutcome(sourceEnvironmentId: string, extraParams?: HttpParams): Promise<CompareOutcome> {
        let params = this.getContextParams(extraParams).set('sourceEnvironmentId', sourceEnvironmentId);
        return firstValueFrom(this.httpClient.get<CompareOutcome>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/compare`, { params: params }));
    }

}

export interface CompareOutcome {
    diffs: EntityDiff[];
}

export interface EntityDiff {
    id: string;
    entity: string;
    label: string;
    itemDiffs: ItemDiff[];
}

export interface ItemDiff {
    id: string;
    label: string;
    leftId: string;
    rightId: string;
    changes: ItemChange[];
    diffs: EntityDiff[];
    leftConsoleUiUrl: string;
    rightConsoleUiUrl: string;
}

export interface ItemChange {
    type: ItemChangeType;
    name: string;
    label: string;
    leftLabel: string;
    rightLabel: string;
}

export enum ItemChangeType {
    PROPERTY_ADDED = 'PROPERTY_ADDED',
    PROPERTY_UPDATED = 'PROPERTY_UPDATED',
    PROPERTY_REMOVED = 'PROPERTY_REMOVED'
}